import { navigate } from 'gatsby'

/**
 * Internal nav function to log path
 * @param {*} path 
 */
const nav = (path, state = {}) => {
    console.debug(`navTo: ${path}`)
    navigate(path, { state })
}

export const navTo = {
    introduction_1: (state) => nav('/introduction-1', state),
    howitworks_2: (state) => nav('/howitworks-2', state),
    letsgetyouverified_3: (state) => nav('/letsgetyouverified-3', state),
    letsgetyouverifiedotp_4: (state) => nav('/letsgetyouverifiedotp-4', state),
    selectyourbank_5: (state) => nav('/selectyourbank-5', state),
    selectyourdata_6: (state) => nav('/selectyourdata-6', state),
    summary_7: (state) => nav('/summary-7', state),
    connectwithyourbank_8: (state) => nav('/connectwithyourbank-8', state),
    collectingyourdata_9: (state) => nav('/collectingyourdata-9', state),
    congrats_10: (state) => nav('/congrats-10', state),
    viewdata_11: (state) => nav('/viewdata-11', state),
    commercial: (state) => nav('/pro', state),
    dashboard: (state) => nav('/pro/dashboard', state),
    consentdetail: (state) => nav('/pro/consentdetails', state),
    configureyourconsent_2: (state) => nav('/pro/configureyourconsent-2', state)
}
